<template>
    <div class="hotGood-container">
        <div style="display: flex; align-items: center">
            <el-radio-group v-model="day_type" size="medium" class="rank-type" @change="selectRankType" style="margin: 0 20px 0 20px">
                <el-radio-button :label="7">7天</el-radio-button>
                <el-radio-button :label="15">15天</el-radio-button>
                <el-radio-button :label="30">30天</el-radio-button>
<!--                <el-radio-button :label="90">90天</el-radio-button>-->
            </el-radio-group>
            <el-date-picker size="medium" v-model="dateTime" @change="toggleDate"
                            :picker-options="pickerOptions"
                            :clearable="false"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"></el-date-picker>
        </div>

        <div class="eCharts-item">
            <div class="item-title">
                <span>抖音订单量</span>
            </div>
            <div class="item-content" id="salesDayIncrement"></div>
        </div>
        <div class="eCharts-item">
            <div class="item-title">
                <span>抖音浏览量</span>
            </div>
            <div class="item-content" id="viewsDayIncrement"></div>
        </div>
        <el-table style="width: calc(100% - 40px); margin: 20px;" :data="infoList1" size="medium" height="264"
                  :header-cell-style="{fontWeight: 'normal', color: '#333'}" :cell-style="{fontSize: '14px',color: '#333'}">
            <el-table-column align="center" prop="update_time" label="日期"></el-table-column>
            <el-table-column align="center" prop="order_count" label="抖音订单数"></el-table-column>
            <el-table-column align="center" prop="pv_count" label="抖音商品浏览量"></el-table-column>
            <el-table-column align="center" label="转化率(%)">
                <template slot-scope="scope">
                    {{scope.row.douyin_conversion_rate}}%
                </template>
            </el-table-column>
            <el-table-column align="center" prop="day_aweme_count" label="视频个数"></el-table-column>
            <el-table-column align="center" prop="room_count" label="直播个数"></el-table-column>
            <el-table-column align="center" prop="live_and_user_count" label="热推达人数"></el-table-column>
        </el-table>
        <el-pagination style="text-align: center; margin-top: 10px"
                       background
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next"
                       :total="listPages.total"
                       @current-change="pageCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { chanmamaGoodsOfficialGoodsHot } from '@/utils/apis.js'
    import * as dayjs from "dayjs";

    export default {
        data() {
            return {
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
                goodsId: Number(this.$route.query.id) || '',

                salesDayIncrement: null,
                viewsDayIncrement: null,

                day_type: 30, // 日期选择
                start_date: dayjs().subtract(29, 'day').format('YYYY-MM-DD'),
                end_date: dayjs().format('YYYY-MM-DD'),
                infoList: [],
                infoList1: [],
                listPages: {
                    currentPageNum: 0,
                    eachPageNum: 5,
                    total: 0,
                },

                dateTime: [dayjs().subtract(29, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
            }
        },
        mounted() {
            this.getHotAnalysis()
            // this.listPages.currentPageNum = 1
        },
        watch: {
            'listPages.currentPageNum': {
                handler: function (val) {
                    let list = this.infoList
                    let tmp = list.slice((val - 1) * 5, (val - 1) * 5 + 5)
                    this.infoList1 = tmp
                },
                deep: true
            }
        },
        methods: {
            // 获取详情
            getHotAnalysis() {
                let params = {
                    promotion_id: this.$route.query.id,
                    start_date: this.start_date,
                    end_date: this.end_date,
                }
                chanmamaGoodsOfficialGoodsHot(params).then(res => {
                    if (res.code === 200) {
                        this.infoList = res.data
                        this.listPages.total = res.data.length
                        this.listPages.currentPageNum = 1

                        let data1 = this.formatData(res.data, 'update_time', 'order_count')
                        let data2 = this.formatData(res.data, 'update_time', 'pv_count')

                        this.$nextTick(() => {
                            this.salesDayIncrement = this.$echarts.init(document.getElementById('salesDayIncrement'));
                            this.viewsDayIncrement = this.$echarts.init(document.getElementById('viewsDayIncrement'));

                            let resizeTimer = null;
                            window.onresize = () => {
                                clearTimeout(resizeTimer);
                                resizeTimer = setTimeout(() => {
                                    this.salesDayIncrement.resize()
                                    this.viewsDayIncrement.resize()
                                }, 300)
                            }
                            this.setEChartsOption(this.salesDayIncrement, data1.xData, data1.yData, '抖音订单量')
                            this.setEChartsOption(this.viewsDayIncrement, data2.xData, data2.yData, '抖音浏览量')
                        })
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch(err => {})
            },
            // 切换分页
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
            },

            // 选择日期区间
            toggleDate(val) {
                this.day_type = 0
                this.start_date = val[0]
                this.end_date = val[1]
                this.getHotAnalysis()
            },

            //选择天数
            selectRankType(val) {
                this.start_date = dayjs().subtract(val - 1, 'day').format('YYYY-MM-DD')
                this.end_date = dayjs().format('YYYY-MM-DD')
                this.dateTime = [dayjs().subtract(val - 1, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
                this.getHotAnalysis()
            },

            // 设置折线图
            setEChartsOption(item, xData, yData, title) {
                item.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: xData,
                        axisLabel: {
                            textStyle: {
                                color: '#333'
                            },
                            rotate: 40,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#EAEAEA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            textStyle: {
                                color: '#333'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'transparent'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#EAEAEA'
                            }
                        }
                    },
                    grid: {
                        top: 20,
                        left: 60,
                        right: 0,
                        bottom: 60,
                    },
                    series: [{
                        name: title,
                        type: 'line',
                        data: yData,
                        itemStyle: {
                            normal: {
                                color: "#42D7AB",
                            }
                        },
                        areaStyle: {
                            color: '#cff2e6'
                        },
                    }]
                });
            },

            // 处理数据格式
            formatData(listArr, x, y) {
                let data = {
                    xData: [],
                    yData: []
                }
                for (let i = 0; i < listArr.length; i++) {
                    data.xData.push(listArr[i][`${x}`]);
                    data.yData.push(listArr[i][`${y}`]);
                }
                return data
            },

            // 视频推广播主分析数据处理
            // formatVideoData(listArr, numType) {
            //     let arr = []
            //     for (const key in listArr) {
            //         let item = listArr[key]
            //         let tmp = {
            //             name: item.single_tags,
            //             value: Number(item[`${numType}`].toFixed(2)),
            //         }
            //         arr.push(tmp)
            //     }
            //     arr.sort((a, b) => {return b.value - a.value})
            //     return arr
            // },

            // 饼图 eCharts
            // setPieChart(item, data, title) {
            //     item.setOption({
            //         tooltip: {
            //             axisPointer: {
            //                 type: 'cross',
            //                 label: {
            //                     backgroundColor: '#6a7985'
            //                 }
            //             },
            //             // formatter: '{b}：{c}%',
            //             formatter: '{a} <br/>{b}: {c} ({d}%)'
            //         },
            //         grid: {
            //             top: 0,
            //             left: 0,
            //             right: 0,
            //             bottom: 0,
            //         },
            //         series: [{
            //             name: title,
            //             type: 'pie',
            //             radius: ['50%', '80%'],
            //             itemStyle: {
            //                 borderRadius: 6,
            //                 borderColor: '#fff',
            //                 borderWidth: 2
            //             },
            //             data: data,
            //             color: ['#51c31b', '#65ca30', '#7fe44e', '#85d65e', '#96dd75', '#a7e18c', '#b9e8a4', '#cbeeba', '#dcf5d2', '#edfce9'], //设置对应块的数据
            //             label: {
            //                 color: '#333',
            //                 // formatter: '{c}%\n{b}',
            //                 formatter: '{b}',
            //             },
            //         }]
            //     });
            // },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #1E33E3;
        border-color: #1E33E3;
    }
    .hotGood-container {
        /*height: calc(100% - 40px);*/
        margin: 20px 0;
        /*::v-deep .el-scrollbar__wrap {*/
        /*    overflow-x: hidden;*/
        /*}*/
        .eCharts-item {
            margin-top: 20px;
            padding: 0 20px;
            &:first-child {
                margin-top: 0;
            }
            .item-title {
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                .toggle-day {
                    .day {
                        cursor: pointer;
                        color: #999;
                        &.current, &:hover {
                            color: #42D7AB;
                        }
                    }
                }
            }
            .item-content {
                /*background: #ffa72a;*/
                margin-top: 10px;
                width: 100%;
                height: 340px;
            }
            .select-title {
                font-size: 16px;
            }
            .video-box {
                display: flex;
                margin-top: 20px;
                .video-item {
                    width: calc(50% - 10px);
                    .video-title {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .no-data {
        height: 200px;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>