<template>
    <div class="promoteGood-container">
        <div style="display: flex; align-items: center">
            <el-radio-group v-model="day_type" size="medium" class="rank-type" @change="selectRankType">
                <el-radio-button :label="7">7天</el-radio-button>
                <el-radio-button :label="15">15天</el-radio-button>
                <el-radio-button :label="30">30天</el-radio-button>
<!--                <el-radio-button :label="90">90天</el-radio-button>-->
            </el-radio-group>
            <el-date-picker size="medium" v-model="dateTime" @change="toggleDate"
                            :picker-options="pickerOptions"
                            :clearable="false"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期" style="margin: 0 20px"></el-date-picker>
            <el-input size="medium" @change="selectSearch" class="search-input" style="width: 200px"
                      placeholder="请输入视频标题或达人名称" prefix-icon="el-icon-search" v-model="keyword"></el-input>
        </div>

        <el-table :data="dyHostVideoList" style="width: 100%; min-height: calc(100vh - 468px); margin-top: 10px" size="medium" class="listTable"
                  :header-cell-style="{fontWeight: 'normal', color: '#333'}" :cell-style="{fontSize: '14px',color: '#333'}" @sort-change="sortChange">
            <el-table-column label="视频" min-width="200">
                <template slot-scope="scope">
                    <a :href="scope.row.aweme_url" target="_blank" class="goods-wrapper">
                        <div class="goods-cover">
                            <img :src="scope.row.aweme_cover" alt="">
                        </div>
                        <div class="goods-detail">
                            <div class="goods-title text-overflow-2" :title="scope.row.aweme_title">视频标题：{{scope.row.aweme_title}}</div>
                        </div>
                    </a>
                </template>
            </el-table-column>
            <el-table-column label="达人" min-width="200">
                <template slot-scope="scope">
                    <div class="goods-wrapper">
                        <div class="user-cover">
                            <img :src="scope.row.avatar" alt="">
                        </div>
                        <div class="goods-detail">
                            <div class="goods-title text-overflow-2" :title="scope.row.nickname">视频标题：{{scope.row.nickname}}</div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="time" label="发布时间" sortable="custom" align="center" min-width="100">
                <template slot-scope="scope">{{scope.row.aweme_create_time}}</template>
            </el-table-column>
            <el-table-column prop="volume" label="预估销量(件)" sortable="custom" align="center" min-width="130">
                <template slot-scope="scope">{{scope.row.product_volume}}</template>
            </el-table-column>
            <el-table-column prop="amount" label="预估销售额(元)" sortable="custom" align="center" min-width="140">
                <template slot-scope="scope">{{scope.row.product_amount}}</template>
            </el-table-column>
            <el-table-column prop="digg" label="点赞" sortable="custom" align="center" min-width="80">
                <template slot-scope="scope">{{scope.row.digg_count}}</template>
            </el-table-column>
            <el-table-column prop="comment" label="评论" sortable="custom" align="center" min-width="80">
                <template slot-scope="scope">{{scope.row.comment_count}}</template>
            </el-table-column>
            <el-table-column prop="share" label="转发" sortable="custom" align="center" min-width="80">
                <template slot-scope="scope">{{scope.row.share_count}}</template>
            </el-table-column>
        </el-table>

        <el-pagination
                v-if="dyHostVideoList.length > 0"
                class="pages-center" background
                :current-page="listPages.currentPageNum"
                :page-size="listPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="listPages.total"
                @current-change="pageCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { chanmamaGoodsOfficialGoodsVideo } from '@/utils/apis.js'
    import * as dayjs from "dayjs";

    export default {
        data() {
            return {
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
                dyHostVideoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },

                day_type: 30, // 日期选择
                start_time: dayjs().subtract(29, 'day').format('YYYY-MM-DD'),
                end_time: dayjs().format('YYYY-MM-DD'),
                dateTime: [dayjs().subtract(29, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
                keyword: '',
                order: 'desc',
                orderby: 'time',
            }
        },
        mounted() {
            this.getDyPromoteVideo()
        },
        methods: {
            // 选择日期区间
            toggleDate(val) {
                this.day_type = 0
                this.start_time = val[0]
                this.end_time = val[1]
                this.listPages.currentPageNum = 1
                this.getDyPromoteVideo()
            },
            // 获取列表
            getDyPromoteVideo() {
                let params = {
                    end_time: this.end_time,
                    filter_delete: 0,
                    keyword: this.keyword,
                    order: this.order,
                    orderby: this.orderby,
                    page: this.listPages.currentPageNum,
                    page_size: this.listPages.eachPageNum,
                    promotion_id: this.$route.query.id,
                    start_time: this.start_time,
                }
                chanmamaGoodsOfficialGoodsVideo(params).then(res => {
                    if (res.code === 200) {
                        this.dyHostVideoList = res.data.list
                        this.listPages.total = res.data.tatal
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch(err => {})
            },
            // 切换分页
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getDyPromoteVideo()
            },
            // 排序
            sortChange(column, prop, order) {
                this.orderby = column.prop
                if (column.order === 'ascending') {
                    this.order = 'asc'
                } else if (column.order === 'descending') {
                    this.order = 'desc'
                } else {
                    this.order = 'asc'
                }
                this.listPages.currentPageNum = 1
                this.getDyPromoteVideo()
            },
            //选择日榜，周榜，月榜
            selectRankType(val) {
                this.start_time = dayjs().subtract(val - 1, 'day').format('YYYY-MM-DD')
                this.end_time = dayjs().format('YYYY-MM-DD')
                this.dateTime = [dayjs().subtract(val - 1, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
                this.listPages.currentPageNum = 1
                this.getDyPromoteVideo();
            },
            // 搜索
            selectSearch(val) {
                this.keyword = val;
                this.listPages.currentPageNum = 1
                this.getDyPromoteVideo();
            },
        }
    }
</script>

<style scoped lang="scss">
    .promoteGood-container {
        padding: 20px;
        /*height: calc(100% - 40px);*/
        /*display: flex;*/
        /*flex-direction: column;*/
    }
    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #1E33E3;
        border-color: #1E33E3;
    }
    .dy-header {
        display: flex;
        align-items: center;
        .rank-type {
            margin-right: 20px;
        }
        .rank-date {
            margin-right: 20px;
        }
        .search-input {
            width: 300px;
        }
    }
    // 表格内样式
    .listTable {
        .goods-wrapper {
            display: flex;
            align-items: center;
            .user-cover {
                width: 24px;
                height: 24px;
                overflow: hidden;
                background: #f3f3f6;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-cover {
                width: 36px;
                height: 52px;
                overflow: hidden;
                background: #f3f3f6;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-detail {
                width: 1%;
                flex: 1;
                margin-left: 10px;
                color: #333;
            }
            .hot {
                &:after {
                    content: '、';
                }
                &:last-child {
                    &:after {
                        content: none;
                    }
                }
            }
            &:hover {
                .goods-title {
                    color: #1E33E3;
                }
            }
        }
        .author-name {
            white-space: nowrap;
            color: #2338E6;
        }
    }

    .card-box {
        margin-top: 10px;
        .card-title {
            font-size: 16px;
        }
        .card-content {
            display: flex;
            margin-top: 10px;
            background: #f2f2f2;
            .card-item {
                width: 1%;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 120px;
                .text {
                    font-size: 16px;
                    color: #666;
                }
                .num {
                    margin-top: 10px;
                    font-weight: 700;
                    font-size: 20px;
                }
            }
        }
    }
</style>